@keyframes glow {
  0% { text-shadow: 0 0 5px #00f, 0 0 10px #00f, 0 0 15px #00f; }
  50% { text-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff; }
  100% { text-shadow: 0 0 5px #00f, 0 0 10px #00f, 0 0 15px #00f; }
}
@keyframes borderAnimation {
  0% {
    border-color: #3b82f6; /* Blue-500 */
  }
  25% {
    border-color: #ef4444; /* Red-500 */
  }
  50% {
    border-color: #0ff; /* Red-500 */
  }
  75% {
    border-color: #00f; /* Red-500 */
  }
  100% {
    border-color: #3b82f6; /* Blue-500 */
  }
}

.animate-border {
  animation: borderAnimation 2s infinite alternate;
}